import {
  CreateControllerFn,
  ControllerParams,
} from 'yoshi-flow-editor-runtime';
import { getSettingsValues } from '@wix/tpa-settings';
// import { ITEM_TYPES } from '@wix/advanced-seo-utils/editor';
import { componentSettings } from './componentSettings';
import HttpClient from 'yoshi-serverless-client';
import { getConfig } from '../../api/config.api';
import {
  dummyViewModelFactory,
  servicePageViewModelFactory,
} from '../../service-page-view-model/servicePageViewModel';

const createController: CreateControllerFn = async ({
  flowAPI,
  appData,
}: ControllerParams) => {
  const errorReporter = appData?.ravenReporter || (() => null);
  const { setProps } = flowAPI.controllerConfig;

  const isMobile = flowAPI.isMobile();
  const isRTL = flowAPI.isRTL();

  return {
    async pageReady() {
      const instance = flowAPI.controllerConfig.appParams.instance as string;
      const wixCodeApi = flowAPI.controllerConfig.wixCodeApi;
      const serviceSlugIndex = 1;
      const serviceSlug = wixCodeApi.location.path[serviceSlugIndex];
      const isPreview = wixCodeApi.window.viewMode === 'Preview';
      const translations = await flowAPI.getTranslations();
      const httpClient = new HttpClient({ isSSR: flowAPI.isSSR() });
      const config = await httpClient.request(getConfig, {
        headers: { authorization: instance },
      })(serviceSlug, isPreview);

      /* await wixCodeApi['wix-seo'].renderSEOTags({
        itemType: ITEM_TYPES.BOOKINGS_SERVICE,
        itemData: config.SEO.serviceResponse,
        seoData: userOverrides //JSON string from BO SEO panel
      });*/

      const viewModel =
        flowAPI.inEditor && !isPreview
          ? dummyViewModelFactory({ translations })
          : servicePageViewModelFactory({
              config,
              translations,
            });
      setProps({
        viewModel,
        isMobile,
        isRTL,
      });
    },
  };
};

export default createController;
